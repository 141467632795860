import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import '../../global-css/index.css'

const NavBar = () => {
  return (
    <>
      <Background>
        <NavBarContainer>
          <NavItem>
            <StyledLink to="/" activeClassName="active-nav">
              PHILOSOPHY
            </StyledLink>
          </NavItem>
          <NavItem>
            <StyledLink
              to="/portfolio"
              partiallyActive
              activeClassName="active-nav"
            >
              PORTFOLIO
            </StyledLink>
          </NavItem>
          <NavItem>
            <StyledLink to="/talk" partiallyActive activeClassName="active-nav">
              TALK
            </StyledLink>
          </NavItem>
        </NavBarContainer>
      </Background>
    </>
  )
}

export default NavBar

const Background = styled.div`
  background-color: black;
`
const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: black;
  padding: 15px 3%;
  max-width: 1200px;
  margin: 0px auto;
  @media screen and (min-width: 768px) {
    padding-top: 30px;
  }
`

const NavItem = styled.div`
  color: white;
  display: flex;
  justify-content: center;
  font-size: 18px;
  padding: 0px 15px;

  :active {
    background-color: transparent;
  }

  @media screen and (min-width: 375px) {
    font-size: calc(18px + (55 - 18) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 55px;
  }
`

const StyledLink = styled(Link)`
  color: white;
  text-decoration: none;
  border-radius: 1px;
  user-select: none;

  :hover {
    cursor: pointer;
  }

  &.active-nav {
    position: relative;
  }

  &.active-nav:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 0;
    border: 3px solid white;
    border-radius: 10px;
  }
`
