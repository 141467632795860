import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

const NavBar = () => {
  return (
    <NavBarContainer>
      <NavItem>
        <StyledLink to="/portfolio/mapfit" activeClassName="active-nav">
          MAPFIT
        </StyledLink>
      </NavItem>
      <NavItem>
        <StyledLink to="/portfolio/spade-data" activeClassName="active-nav">
          SPADE DATA
        </StyledLink>
      </NavItem>
      <NavItem>
        <StyledLink to="/portfolio/rta-transport" activeClassName="active-nav">
          RTA TRANSPORT
        </StyledLink>
      </NavItem>
      <NavItem>
        <StyledLink to="/portfolio/wishlist-autos" activeClassName="active-nav">
          WISHLIST AUTOS
        </StyledLink>
      </NavItem>
      <NavItem>
        <StyledLink to="/portfolio/fly-bank" activeClassName="active-nav">
          FLY BANK
        </StyledLink>
      </NavItem>
    </NavBarContainer>
  )
}

export default NavBar

const NavBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 10px 10px;
  overflow: scroll;
  max-width: 1200px;
  margin: 0px auto;

  @media screen and (min-width: 375px) {
    padding: 2.67% 2.67%;
  }

  @media screen and (min-width: 1200px) {
    padding: 30px 30px;
  }
`

const NavItem = styled.div`
  color: black;
  display: flex;
  justify-content: center;
  font-size: 10px;
  padding: 0px 0px;
  white-space: nowrap;

  @media screen and (min-width: 375px) {
    font-size: calc(10px + (28 - 10) * ((100vw - 375px) / (1200 - 375)));
  }

  @media screen and (min-width: 1200px) {
    font-size: 28px;
  }
`

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  border-radius: 1px;

  :hover {
    cursor: pointer;
  }

  &.active-nav {
    position: relative;
  }

  &.active-nav:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -5px;
    height: 0;
    border: 3px solid black;
    border-radius: 10px;
  }
`
