import NavBar from '../components/navbar/index'
import PortfolioNavBar from '../components/portfolio/portfolio-navbar'
import React from 'react'
import { Normalize } from 'styled-normalize'
import styled from 'styled-components'

export default ({ children, pageContext }) => {
  if (pageContext.layout === 'portfolio') {
    return (
      <BackgroundWhite>
        <Normalize />
        <NavBar />
        <PortfolioNavBar />
        {children}
      </BackgroundWhite>
    )
  }
  return (
    <BackgroundBlack>
      <Normalize />
      <NavBar />
      {children}
    </BackgroundBlack>
  )
}

const BackgroundBlack = styled.div`
  background-color: black;
`
const BackgroundWhite = styled.div`
  background-color: white;
`
