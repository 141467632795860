// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-fly-bank-js": () => import("/opt/build/repo/src/pages/portfolio/fly-bank.js" /* webpackChunkName: "component---src-pages-portfolio-fly-bank-js" */),
  "component---src-pages-portfolio-index-js": () => import("/opt/build/repo/src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-mapfit-js": () => import("/opt/build/repo/src/pages/portfolio/mapfit.js" /* webpackChunkName: "component---src-pages-portfolio-mapfit-js" */),
  "component---src-pages-portfolio-rta-transport-js": () => import("/opt/build/repo/src/pages/portfolio/rta-transport.js" /* webpackChunkName: "component---src-pages-portfolio-rta-transport-js" */),
  "component---src-pages-portfolio-spade-data-js": () => import("/opt/build/repo/src/pages/portfolio/spade-data.js" /* webpackChunkName: "component---src-pages-portfolio-spade-data-js" */),
  "component---src-pages-portfolio-wishlist-autos-js": () => import("/opt/build/repo/src/pages/portfolio/wishlist-autos.js" /* webpackChunkName: "component---src-pages-portfolio-wishlist-autos-js" */),
  "component---src-pages-talk-index-js": () => import("/opt/build/repo/src/pages/talk/index.js" /* webpackChunkName: "component---src-pages-talk-index-js" */),
  "component---src-pages-talk-success-js": () => import("/opt/build/repo/src/pages/talk/success.js" /* webpackChunkName: "component---src-pages-talk-success-js" */)
}

